<template>

  <div>
    <loader v-if="isLoading"/>
    <!-- <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"> -->

    <div class="flex justify-center w-100">

      <div class="flex flex-wrap w-full flex-col">
        <h1 class="w-full text-center my-5 text-primary text-3xl">
          Listado de tus pólizas
        </h1>
        <!--? Importante -->
        <!-- <div>{{ userData.id }}</div> -->
        <section class = "container_products" style="opacity: 1;">

        <div 
          v-for="quote in QuotesPaid"
          :key="quote.ref_code"
          style="opacity: 1;"
        >

        <div 
        :class="'product ' + quote.style"
        v-bind:style="defaultStyleHeaderCard">
              <div class="header-product-detail">
                <img :src="quote.icon_src" class="img_icon" />
                <h2>{{ quote.title }}</h2>
              </div>

              <div class="product-detail">
                <h2 v-if="!quote.multipolicies">Número de Poliza: <span>{{ quote.policy_number }}</span> <a v-if="quote.policy_number_ref != ''" v-bind:href="quote.policy_number_ref" target="_blank" style="background-color:green; color:white; padding:3px; border-radius:5px;">Ver</a></h2>

                <h2 v-else>
                    Número de Polizas:
                  <h2 v-for="quote_ref in quote.policy_number"
                      :key="quote_ref.title">
                      <li>{{quote_ref['codigo']}} <a v-bind:href="quote_ref['link_voucher']" target="_blank" style="background-color:green; color:white; padding:3px; border-radius:5px;">Ver</a></li>
                  </h2>
                </h2>

                <h2>Prima Total: <span>$ {{ quote.total_price }} MXN</span></h2>
                <h2>Inicio de Vigencia:<span> {{ quote.ini_vigency }} </span> </h2>
                <h2>Fin de Vigencia:<span> {{ quote.end_vigency }} </span></h2>
                <p>{{ quote.description }}</p>
              </div>
        </div>
        </div>         

        </section>
        
        <div class="flex flex-wrap justify-center my-16">
          <p class="w-full text-secondary text-center" v-if="QuotesPaid.length == 0 && !isLoading">
            Aún no tienes ningúna pólizas de seguro, consulta nuestros servicios.
          </p>
          <router-link
            to="/our-services"
            class="w-full mx-10 py-3 my-5 relative double-pulse-animation shadow-md rounded-md bg-special-2 flex justify-center items-center text-white text-center duration-300 transition transform hover:scale-105 sm:cursor-pointer sm:mx-10 sm:my-10 sm:text-3xl sm:w-96 sm:h-20"
          >
            Conoce nuestros servicios
          </router-link>
        </div>
      </div>

    </div>
  </div>
  
</template>



<script>
import { mapGetters } from 'vuex'
import Loader from "../components/Loader";

export default {

  name: "PromotionsPage",
  data: function() {
    return {
      id: "",
      confirmError: true,
      QuotesPaid:[],
      isLoading: false,
      defaultStyleHeaderCard: {
        // background : "rgb(200, 225, 241)",
        color: 'white',
        fontSize: '13px'
      },
    };
  },
  components: {
    Loader
  },
  computed: {
    ...mapGetters(['userData']),
    // header_card_style(){
    //   return {
    //     "background-color": this.background_header_color
    //   }
    // }
    // showMessageWithoutProducts() {
    //   return this.QuotesPaid.length == 0 && !this.isLoading
    // }
  },
  mounted() {
    this.getQuotesPaid()
  },
  destroyed() {},
  methods: {
    
    async getQuotesPaid() {

      this.isLoading = true
      const path = this.$APIEndpoint + this.$APIServices.quotecategory
      await this.$http.get(path).then((response) => {
        // const user_id = this.userData.id
        var response_data = response.data
        // console.log(response_data)
        // console.log(this.QuotesPaid.length)
        
        response_data.forEach((policy,index) => {

          var extra_data = policy.extra_data
          var emition = extra_data['emition']
          var baseData = extra_data['baseData']
          // console.log(payment)
          var payment = extra_data['payment']

          try {

            //THONA - Accidentes Personales (1)
            if(policy.category == 1) {
              this.QuotesPaid.push({
                ref_code: index,
                style: "accidentesPersonalesStyleHeaderCard",
                icon_src: require("../components/InsuranceOptions/img/Accidentes_Personales-min.png"),
                title: (payment['description'])?payment['description']:(policy.id),
                policy_number: extra_data['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: (extra_data['insured_amount'] * 1).toFixed(2),
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            //PRIMERO SEGUROS - BICICLETA (2)
            else if(policy.category == 2) {
              // console.log("Bicicleta")
              this.QuotesPaid.push({
                ref_code: index,
                style: "bicicletaStyleHeaderCard",
                icon_src: require("../components/InsuranceOptions/img/Bicicleta-min.png"),
                title: (payment['description'])?payment['description']:(policy.id),
                policy_number: payment['policy_number'] ,
                policy_number_ref : "",
                multipolicies:false,
                total_price: payment['amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            //THONA - COVID (4)
            else if(policy.category == 4) {
              this.QuotesPaid.push({
                style: "covidStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/COVID-min.png"),
                policy_number: extra_data['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: extra_data['insured_amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            //DENTEGRA (5)
            else if(policy.category == 5){
              this.QuotesPaid.push({
                style: "dentalStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/Dental-min.png"),
                policy_number: payment['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: payment['amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            //THONA - FUNERARIO (6)
            else if(policy.category == 6) {
              this.QuotesPaid.push({
                style: "funerarioStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/Funerario-min.png"),
                policy_number: extra_data['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: extra_data['insured_amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            // CHUBB - HOGAR (8)
            else if(policy.category == 8) {
              this.QuotesPaid.push({
                style: "hogarStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/Hogar-min.png"),
                policy_number: payment['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: payment['amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            // Mascota (9)
            else if(policy.category == 9) {
              this.QuotesPaid.push({
                style: "mascotaStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/Mascotas-min.png"),
                policy_number: payment['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: payment['amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            // Lucero Quirurgico (11)
            else if(policy.category == 11){
              this.QuotesPaid.push({
                style: "quirurgicoStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
                icon_src: require("../components/InsuranceOptions/img/Quirurgico-min.png"),
                policy_number: extra_data['policy_number'],
                policy_number_ref : "",
                multipolicies:false,
                total_price: policy.extra_data['insured_amount'],
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
              })
            }

            //CONTINENTAL - ACCIDENTES VIAJERO (12)
            else if(policy.category == 12) {
              this.QuotesPaid.push({
                style: "viajeroStyleHeaderCard",
                ref_code: index,
                title: (payment['description'])?payment['description']:(policy.id),
        
                icon_src: require("../components/InsuranceOptions/img/Viajes-min.png"),
                policy_number: emition['resultado'],
                policy_number_ref: "",
                multipolicies:true,
                total_price: (baseData['type_of_travel']['name'] == 'Nacional' ? extra_data['insured_amount'] : (extra_data['insured_amount'] * emition['divisa']).toFixed(2)),
                ini_vigency: payment['initial_vigency'],
                end_vigency: payment['end_vigency'],
                // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'
              })
            }

            // //VRIM (13)
            // else if(policy.category == 13) {
            //   this.QuotesPaid.push({
            //     style: "vrimStyleHeaderCard",
                // ref_code: index,
                // title: (payment['description'])?payment['description']:(policy.id),
        
            //     icon_src: require("../components/InsuranceOptions/img/VRIM-min.png"),
            //     policy_number: extra_data['emition_response']['membresia'],
            //     policy_number_ref : "",
            //     multipolicies:false,
            //     total_price: payment['amount'],
            //     ini_vigency: payment['initial_vigency'],
            //     end_vigency: payment['end_vigency'],
            //     // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
            //   })
            // }

            //Default Response
            // else {
              
            //   // console.log(policy.category)
            //   this.QuotesPaid.push({
            //     style: "errorStyleHeaderCard",
                // ref_code: index,
                // //     title: `An error has occurred [${index}]`,
        
            //     icon_src: require("../components/InsuranceOptions/img/Accidentes_Personales-pose-min.png"),
            //     policy_number: "Error",
            //     policy_number_ref : "Error",
            //     multipolicies: false,
            //     total_price: "Error",
            //     ini_vigency: "Error",
            //     end_vigency: "Error",
            //     // description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt eligendi sed cupiditate eius dolores consequatur explicabo dignissimos magnam amet eveniet!'                    
            //   })
            // }

          } catch (error) {
            console.log(error)
          }

        })

      }).catch((error)=> {
        console.log(error)
        throw error;
      }).finally(()=> {
        this.isLoading = false
      })
    }
  }
}

</script>




<style>

  .container_products {
    width:100%;
  }

  @media (min-width: 768px) {
    .container_products {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1200px) {
    .container_products {
        grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1500px) {
    .container_products {
        grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (min-width: 1800px) {
    .container_products {
        grid-template-columns: repeat(5, 1fr);
    }
  }

  .product {
    padding: 10px;
    border-radius: 20px;
    margin: 30px;
    cursor: pointer;
    width:88%;
    margin-right: 10px;
  }
  
  .header-product-detail {
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .product-detail {
    background-color: rgb(243, 243, 243);
    height: 100%;
    padding: 25px;
    margin: 0px -10px -20px;
    border-radius: 0px 0px 10px 10px;
  }

  .product-detail span {
    color: black;
    font-weight: 200;
  }

  .product-detail h2 {
    font-size: 14px;
    font-weight: 700;
    color: #676767;
    margin-bottom: 15px;
  }

  .product-detail p {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
    color: #676767;
    text-align: justify;
  }

  .img_icon{
    width:3rem;
  }










  .accidentesPersonalesStyleHeaderCard {
    background-color: rgba(0, 0, 235, 0.432);
  }
  
  .bicicletaStyleHeaderCard {
    background-color: rgba(159, 152, 39, 0.8);
  }

  .covidStyleHeaderCard {
    background-color: rgb(200, 150, 61 , 1);
  }
  
  .dentalStyleHeaderCard {
    background-color: rgba(41, 145, 214, 0.589);
  }

  .funerarioStyleHeaderCard {
    background-color: rgba(116, 116, 116, 0.712);
  }

  .hogarStyleHeaderCard {
    background-color: rgba(139, 55, 165, 0.507);
  }

  .mascotaStyleHeaderCard {
    background-color: rgb(228, 0, 255,0.4);
  }

  .quirurgicoStyleHeaderCard{
    background-color: rgb(231, 182, 48,1);
  }

  .viajeroStyleHeaderCard{
    background-color: rgba(7, 170, 48, 0.76);
  }

  .vrimStyleHeaderCard{
    background-color: rgba(53, 207, 61, 0.596);
  }

  .errorStyleHeaderCard{
    background-color:rgb(223, 63, 63);
    color:white;
  }


</style>